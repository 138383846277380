import { ComplaintStage } from '@/services/ComplaintService/ComplaintServiceDto';
import { ECallCenterResult } from './CallCenterModal/utils';

export enum EComplaintSource {
  ROS_SETI_MR = 'ROS_SETI_MR', //Россети МР
  CALL_CENTER = 'CALL_CENTER', //Колл-Центр
  ADMIN = 'ADMIN', //Администратор"
}

export enum EComplaintType {
  ENERGY_SERVICE = 'ENERGY_SERVICE',
  ODPU = 'ODPU',
  UNKNOWN = '',
}
export interface ComplaintNextStage {
  title: string;
  name: ComplaintStage;
}

export interface IComplaintItem {
  complaint: {
    stage: {
      title: string;
      name: ComplaintStage;
    };
    nextStages?: ComplaintNextStage[] | null;

    text?: string;
    address: {
      region: string | null;
      area: string | null;
      locality: string | null;
      street: string | null;
      house: string | null;
      apartment: string | null;
      readOnlyAddress: string;
    };

    meterNumber: string;
    base: Base;
    type?: EComplaintType;
    reasonTaskId: string | null;
    reasonTaskNumber: string | null;
    responsibleId: string | null;
    resultTaskId: string | null;
    resultTaskNumber: string | null;
    resultTaskStatus: string | null;
    plannedDate: string | null;
    stageUpdateAt: string | null;
    response?: string | null;
    lastComment: CommentCompalaint | null;
  };

  inputId?: string;
  energyType?: {
    title: string;
    code: number;
    name: string;
  };
  sapNumber?: string;
  missedCalls?: number;

  responseDate?: string;
  declarerFio?: string;
  account?: string;
  callCenterResults?: CallCenterResults[];
  phoneNumber?: string;
  phoneNumberAdditional?: string;
  meterType?: string;
  tariffication?: number;
  consumerReadyToMeet?: string | null;
  pes?: string;
  res?: string;
  odpuType?: string;
  letterDetails?: string;
  responseDetails?: string;
  plannedDate?: string;
  odpuCreateDate?: string;
  classification?: {
    title: string;
    name: string;
  };
  files: any[] | null;
  inputName: string;
  source: EComplaintSource;
  workType: { title: string; name: string };
  executorId: string | null;
  consumerType?: string | null;
}

export interface Filter {
  page: number;
  size: number;
  [key: string]: any; // для дополнительных параметров фильтра
}

export interface CommentCompalaint {
  base: Base;
  text: string;
  complaintId: number;
}

export interface CallCenterResults {
  base: Base;
  resultType: {
    name: ECallCenterResult | null;
    title: string | null;
  };
}

export interface ResultCallsMaped {
  [key: string]: { createAt: string | null }[];
}

interface Base {
  createAt: string | null;
  createBy: string | null;
  deleteAt: string | null;
  deleteBy: string | null;
  id: number;
  updateAt: string | null;
  updateBy: string | null;
  version: string | null;
}
export type ComplaintTypeReturn = { url: string; type: EComplaintType };
