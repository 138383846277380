import { BoxShadow } from '@/components/ui/BoxShadow';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import {
  Badge,
  Box,
  Stack,
  Tab,
  Tabs,
  CircularProgress,
  Tooltip,
  styled,
  TooltipProps,
  tooltipClasses,
  Typography,
} from '@mui/material';
import { color } from '@/styles/mixins';
import { CallingMapForm } from '../form/CallingMapForm';
import { useEffect, useState } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useCallingMapState } from '../store';
import {
  getUrgentPointsCallMap,
  getUrgentPointsCountCallMap,
} from '@/services/CallingMapService/CallingMapService';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined';
import ExecutersList from '../components/ExecutersList';

import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import Legend from '../components/Legend';
import signal from '../images/signal.svg';
import noSignal from '../images/noSignal.svg';
import SearchByAdressIdNumber from '../components/SearchByAdressIdNumber';

export const CallingMapFilter = ({
  onSubmit,
  mapAPI,
  isTableSearch,
  activeTab,
  setActiveTab,
}: any) => {
  const {
    geoPoints,
    setUrgentPoints,
    urgentGeoPoints,
    setToggleUrgentGeoPoints,
    setIsOpenSettings,
    toggleUrgentGeoPoins,
    isAuthorized,
  } = useCallingMapState((state) => ({
    geoPoints: state.geoPoints,
    setUrgentPoints: state.setUrgentPoints,
    urgentGeoPoints: state.urgentGeoPoints,
    setToggleUrgentGeoPoints: state.setToggleUrgentGeoPoints,
    setIsOpenSettings: state.setIsOpenSettings,
    toggleUrgentGeoPoins: state.toggleUrgentGeoPoins,
    isAuthorized: state.isAuthorized,
  }));

  const [isLodingUrgentTask, setIsLodingUrgentTask] = useState(false);
  const [urgentPointsCount, setUrgentPointsCount] = useState(0);
  const { setIsInProgress } = useScreenHoldHook();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 1 || newValue === 0) {
      setIsOpenSettings(true);
    } else {
      setIsOpenSettings(false);
    }
    setActiveTab(newValue);
  };

  const handelCloseSettings = () => {
    setIsOpenSettings(false);
    setActiveTab(-1);
  };
  const handleUrgentRequest = () => {
    getUrgentPointsCountCallMap()
      .then(({ data }) => {
        setUrgentPointsCount(data);
      })
      .finally(() => {
        setIsLodingUrgentTask(false);
      });
  };

  const getUrgentPoints = () => {
    setToggleUrgentGeoPoints();
    setIsInProgress(true);
    getUrgentPointsCallMap()
      .then(({ data }) => {
        setUrgentPoints(data);
      })
      .finally(() => setIsInProgress(false));
  };

  useEffect(() => {
    setIsLodingUrgentTask(true);
    getUrgentPointsCountCallMap()
      .then(({ data }) => {
        setUrgentPointsCount(data);
      })
      .finally(() => {
        setIsLodingUrgentTask(false);
      });
  }, [urgentGeoPoints]);

  useEffect(() => {
    const timerId = setInterval(handleUrgentRequest, 300000);

    // Очистка таймера при размонтировании компонента
    return () => clearInterval(timerId);
  }, []);

  return (
    <BoxShadow p={0}>
      <Stack
        display={'flex'}
        justifyContent={'space-between'}
        flexDirection={'row'}
      >
        <Stack
          flexDirection={'row'}
          alignItems={'center'}
          display={'flex'}
          gap={2}
          ml={3}
        >
          <img
            src={isAuthorized ? signal : noSignal}
            alt='signalImg'
          />
          <span>{isAuthorized ? 'Подключен' : 'Не подключен'}</span>
        </Stack>
        <Stack
          display={'flex'}
          justifyContent={'flex-end'}
          flexDirection={'row'}
        >
          <Tabs
            value={activeTab}
            onChange={handleChange}
            sx={{
              '&& .MuiTab-root': {
                minWidth: 10,
              },
            }}
          >
            <Box
              style={{ padding: 10, cursor: 'pointer', marginRight: 10 }}
              onClick={getUrgentPoints}
            >
              <Badge
                badgeContent={
                  isLodingUrgentTask ? (
                    <Box sx={{ display: 'flex' }}>
                      <CircularProgress size={10} />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        backgroundColor: urgentPointsCount && color('trueRed'),
                        borderRadius: 50,
                        paddingX: 0.9,
                        paddingY: 0.1,
                      }}
                    >
                      <span style={{ color: color('trueWhite') }}>
                        {urgentPointsCount > 0 && urgentPointsCount}
                      </span>
                    </Box>
                  )
                }
              >
                <LightTooltip title='отоброжать на карте только срочные заявки'>
                  <PhoneInTalkOutlinedIcon
                    style={{ color: toggleUrgentGeoPoins ? 'orange' : 'green' }}
                  />
                </LightTooltip>
              </Badge>
            </Box>
            {/* Времено отключен функцилнал загрузки точек без координат что бы включить разкоментируй строку ниже  */}
            {/* <Tab
              label={<LocationOffIcon />}
              value={0}
            /> */}
            <Tab
              label={
                <Badge>
                  <ManageAccountsIcon />
                </Badge>
              }
              value={1}
            />
            <Tab
              label={<CollectionsBookmarkIcon />}
              value={2}
            />
            <Tab
              label={<PlagiarismOutlinedIcon />}
              value={3}
            />
            <Tab
              label={<FilterAltIcon />}
              value={4}
            />
          </Tabs>
        </Stack>
      </Stack>
      {/* Времено отключен функцилнал загрузки точек без координат что бы включить разкоментируй строку ниже  */}
      {/* {activeTab === 0 && <PointsWithoutGeoCords handelCloseSettings={handelCloseSettings} />} */}
      {activeTab === 1 && <ExecutersList handelCloseSettings={handelCloseSettings} />}
      {activeTab === 2 && <Legend handelCloseSettings={handelCloseSettings} />}
      {activeTab === 3 && (
        <SearchByAdressIdNumber
          mapAPI={mapAPI}
          show={activeTab === 3}
          handelCloseSettings={handelCloseSettings}
        />
      )}
      <CallingMapForm
        show={activeTab === 4}
        onSubmit={onSubmit}
        isTableSearch={isTableSearch}
        onClose={() => setActiveTab(100)}
      />
    </BoxShadow>
  );
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[2],
    fontSize: 11,
  },
}));
