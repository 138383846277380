import { ReactNode, MouseEvent, useEffect } from 'react';
import { Button, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { DownloadPopup } from '@/components/popups/DownloadPopup/DownloadPopup';
import { ExpandButton } from '@/components/button/ExpandButton/ExpandButton';
import { IdPopup } from '../panel/IdPopup';
import { DesktopDatePicker, TextField, ClearingTextField } from '@/components/fixUI';
import SaveIcon from '@mui/icons-material/Save';
import { ModeViewerResult } from '@/components/features/taskEditor/types';
import { toFormateDate } from '@/utils/heplers';
import { useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useCatalog } from '@/hooks/CatalogHook';
import { Phase, WarrantyWorkType } from '@/dto/taskmap/Dto';
import { useFormContext } from 'react-hook-form';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import {
  canceledWaranty,
  getAutomaticBreaker,
  upDateTaskTransformer,
  updateWaranty,
} from '@/services/WarantyService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { useGalleryData } from '@/components/Gallery/store';
import { getWarrantyResImageGroup } from './utils';
import { AutocompleteValueMulti } from '@/components/inputs/AutocompleteValueMulti/AutocompleteValueMulti';
import useWarrantyFields, {
  ShowFieldsKey,
  ShowFields,
  KeyForwarrrantyTypeFields,
} from '@/components/features/taskEditor/hooks/useWarantyFields';
import CopyButton from '@/components/button/CopyButton/CopyButton';
import { TaskEditorOverflowText, TASK_EDITOR_MEDIUM_PREVIEW } from '../item/TaskEditorItem.styled';
import { useDisabledAll } from '@/hooks/useAccess';
import { GSMBlockNew } from '@/components/GSM/GSMBlockNew';
import { useTaskEditorOnlyShow } from '../useTaskEditorOnlyShow';
import { WarrantyStateIcon } from '@/components/features/taskEditor/panel/WarrantyStateIcon/WarrantyStateIcon';

interface WarrantyProps {
  warrantyResponse: WarrantyWorkType;
  mode?: ModeViewerResult;
  children?: React.ReactNode;
  ids?: {
    executorId?: string;
    id?: string;
    contractorId?: string;
    vruId?: string;
    contractId?: string;
  };
  taskType?: string;
  updateWarranty: () => void;
}

function WarrantyPanel(props: WarrantyProps) {
  const onlyShow = useTaskEditorOnlyShow();
  const disabledAll = useDisabledAll();
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const setImageGroups = useGalleryData(({ setImageGroups }) => setImageGroups);

  const { ids, warrantyResponse, updateWarranty } = props;
  const data = warrantyResponse.id;

  const methods = useFormContext();
  const { addActionLog, fetchCatch } = useActionLog();
  const { setIsInProgress } = useScreenHoldHook();
  const { fields, updateFields } = useWarrantyFields();

  const [isOpenDownload, setIsOpenDownload] = useState<boolean>(false);
  const [mode, setMode] = useState<ModeViewerResult>(ModeViewerResult.SMALL);

  const [optionsAutomaticBreaker, setOptionsAutomaticBreaker] = useState<Map<string, string>>();
  useEffect(() => {
    const contractId = props?.ids?.contractId;
    contractId &&
      getAutomaticBreaker(contractId)
        .then(({ data }) => {
          setOptionsAutomaticBreaker(
            new Map(data.map((elem) => [elem.id, elem.automaticBreakerName]))
          );
        })
        .catch((err) => fetchCatch(err, 'Значения для автоматического выключателя не загружены'));
  }, [props]);

  const [eventOn, setEventOn] = useState<Date | undefined>(warrantyResponse?.workDoneAt);
  const handleMode = (forMode: ModeViewerResult) => (isExpanded: boolean) => {
    if (forMode === ModeViewerResult.SMALL) {
      setMode(isExpanded ? ModeViewerResult.LARGE : ModeViewerResult.SMALL);
    } else if (forMode === ModeViewerResult.LARGE) {
      setMode(isExpanded ? ModeViewerResult.LARGE : ModeViewerResult.SMALL);
    }
  };

  const onSave = (data: any) => {
    if (warrantyResponse && !onlyShow && !disabledAll) {
      const id = warrantyResponse.id;
      if (id && data) {
        const fieldsKeys = Object.keys(fields) as ShowFieldsKey[];
        const activeFields = fieldsKeys?.filter((key) => fields[key] === true);
        const updatingData: ShowFields & {
          typeIds?: string[];
          transferToAccounting?: any;
          taskTransformatorData?: any;
        } = {
          transferToAccounting: {},
          taskTransformatorData: {},
        };
        const formData = data[id];
        activeFields.forEach((key) => {
          if (
            key === 'previousAccountingPuNumber' ||
            key === 'newAccountingPuNumber' ||
            key === 'previousAccountingPuType' ||
            key === 'newAccountingPuType'
          ) {
            updatingData.transferToAccounting[key] = formData[key];
          } else if (key === 'type' || key === 'taskTransformator') {
            // Обработка taskTransformer
            if (key === 'taskTransformator') {
              updatingData.taskTransformatorData = formData.taskTransformator;
            } else {
              updatingData.taskTransformatorData[key] = formData[key];
            }
          } else {
            updatingData[key] = formData[key];
          }
        });
        updatingData.typeIds = formData?.typeIds;
        // updatingData.typeIds = formData?.typeIds;
        setIsInProgress(true);
        if (
          formData?.typeIds.includes(KeyForwarrrantyTypeFields.CURRENT_TRANSFORMER_REPLACEMENT) &&
          Object.keys(updatingData.taskTransformatorData)?.length
        ) {
          upDateTaskTransformer({
            ...updatingData.taskTransformatorData,
          }).catch((err) => {
            fetchCatch(err, `Ошибка при сохранении ТТ`);
          });
        }
        const { taskTransformatorData, ...restUpdatingData } = updatingData;
        updateWaranty(id, restUpdatingData)
          .then(() => {
            addActionLog(ActionLogType.SUCCESS, `Гарантия успешно сохранена`);
            updateWarranty && updateWarranty();
          })
          .catch((err) => {
            fetchCatch(err, `Ошибка при сохранении гарантии`);
          })
          .finally(() => setIsInProgress(false));
      } else {
        throw new Error('Ошибка при сохранении гарантии, данные некорректны');
      }
    }
  };

  const handleCancelWarrantyWork = () => {
    const id = warrantyResponse.id;
    setIsInProgress(true);
    canceledWaranty(id)
      .then(() => {
        addActionLog(ActionLogType.SUCCESS, `Гарантия успешно отменена`);
        updateWarranty && updateWarranty();
      })
      .catch((err) => {
        fetchCatch(err, `Ошибка при отмене гарантии`);
      })
      .finally(() => setIsInProgress(false));
  };

  const disableCancelWarrantyButton = warrantyResponse.status === 'CANCELED';

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setImageGroups(getWarrantyResImageGroup(warrantyResponse));
  };

  const getPreview = (): ReactNode => {
    return (
      <Grid {...TASK_EDITOR_MEDIUM_PREVIEW.inner}>
        <Grid {...TASK_EDITOR_MEDIUM_PREVIEW.firstItem}>
          <Typography>
            <WarrantyStateIcon status={warrantyResponse?.status} />
            <span style={{ marginRight: 10 }}>
              {getCatalogMapWithErr('warrantyWorkStatus')?.get(warrantyResponse?.status || '')}
            </span>
            <span style={{ fontWeight: 'bold' }}>
              {' '}
              {toFormateDate(warrantyResponse?.workDoneAt) || toFormateDate(new Date())}
            </span>
          </Typography>
        </Grid>
        <Grid {...TASK_EDITOR_MEDIUM_PREVIEW.secondItem}>
          <TaskEditorOverflowText fontWeight={'bold'}>
            {warrantyResponse?.executor}
          </TaskEditorOverflowText>
        </Grid>
        <Grid {...TASK_EDITOR_MEDIUM_PREVIEW.thirdItem}>
          <Typography fontWeight={'bold'}>MAC: {warrantyResponse?.mac || '-'}</Typography>
        </Grid>
        <Grid {...TASK_EDITOR_MEDIUM_PREVIEW.lastItem}>
          <Stack
            direction={'row'}
            justifyContent={'flex-end'}
          >
            <Typography>
              {warrantyResponse?.type ||
                (warrantyResponse?.typeIds &&
                  getCatalogMapWithErr('warrantyWorkType').get(warrantyResponse?.typeIds[0]))}
            </Typography>

            <ExpandButton
              expand={mode != ModeViewerResult.SMALL}
              onAction={handleMode(ModeViewerResult.SMALL)}
              sx={{ marginLeft: 1 }}
            />
          </Stack>
        </Grid>
      </Grid>
    );
  };

  const onHandleEventOn = (newValue: Date | null) => {
    if (newValue) {
      setEventOn(newValue);
      methods.setValue(`${data}.workDoneAt`, moment(newValue).valueOf());
    }
  };
  return (
    <BoxShadow onDoubleClick={handleClick}>
      <DownloadPopup
        id={'1'}
        isOpen={isOpenDownload}
        onClose={() => setIsOpenDownload(false)}
      />
      {mode === ModeViewerResult.SMALL && <>{getPreview()}</>}
      {(mode === ModeViewerResult.MEDIUM || mode === ModeViewerResult.LARGE) && (
        <Stack
          width={'100%'}
          spacing={3}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <Typography style={{ fontWeight: 'bold', fontSize: '18px' }}>
                {warrantyResponse?.type}
              </Typography>
              <Stack
                direction={'row'}
                sx={{ alignItems: 'center', marginTop: 2 }}
              >
                <Typography mr={4}>
                  <WarrantyStateIcon status={warrantyResponse?.status} />
                  {getCatalogMapWithErr('warrantyWorkStatus')?.get(warrantyResponse?.status || '')}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>Состояние Пломбы:</span>{' '}
                  {getCatalogMapWithErr('warrantyWorkStatus')?.get(
                    warrantyResponse?.stampStatus || ''
                  )}
                </Typography>
                <Typography ml={4}>
                  <span style={{ fontWeight: 'bold' }}>Статус Показаний:</span>{' '}
                  {getCatalogMapWithErr('measurementStatus')?.get(
                    warrantyResponse?.measurementStatus || ''
                  )}
                </Typography>
                <Typography ml={4}>
                  <span style={{ fontWeight: 'bold' }}>Состояние SIM:</span>{' '}
                  {getCatalogMapWithErr('simChangeStatus')?.get(warrantyResponse?.simStatus || '')}
                </Typography>
                <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                  <IdPopup sx={{ color: 'gray' }}>
                    {ids?.id && (
                      <div>
                        <CopyButton textToCopy={ids?.id} />
                        id заявки {ids?.id}
                      </div>
                    )}
                    {warrantyResponse?.id && (
                      <div>
                        <CopyButton textToCopy={warrantyResponse?.id} /> id warranty{' '}
                        {warrantyResponse?.id}
                      </div>
                    )}
                  </IdPopup>
                </div>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                label='Валидационное сообщение'
                variant='outlined'
                sx={{ mb: 0.3, width: '100%' }}
                disabled
                multiline
                rows={1}
                defaultValue={warrantyResponse?.vldMessages}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <AutocompleteValueMulti
                optionOnChangeValue={updateFields}
                title='Тип'
                defaultValue={warrantyResponse?.typeIds}
                fieldName={`${data}.typeIds`}
                values={getCatalogMapWithErr('warrantyWorkType')}
              />
            </Grid>

            <Grid
              item
              xs={3}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  {...methods.register(`${data}.workDoneAt`, {
                    value: warrantyResponse?.workDoneAt,
                  })}
                  label='Дата замены'
                  inputFormat='dd.MM.yyyy'
                  value={eventOn ? eventOn : new Date()}
                  onChange={onHandleEventOn}
                  renderInput={(params) => (
                    <TextField
                      sx={{ width: '100%', mt: 1 }}
                      {...params}
                      variant='standard'
                    />
                  )}
                />
              </LocalizationProvider>

              {fields.type && (
                <ClearingTextField
                  name={`${data}.taskTransformator.mount.type`}
                  options={{ value: warrantyResponse?.taskTransformator?.mount?.type }}
                  id='tf-warranty-taskTransformer-type'
                  label={'Тип нового ТТ'}
                  variant='standard'
                  sx={{ width: '100%', mt: 1 }}
                />
              )}
              {fields.puNumber && (
                <ClearingTextField
                  name={`${data}.puNumber`}
                  options={{ value: warrantyResponse?.puNumber }}
                  id='tf-warranty-py'
                  label={'Номер ПУ'}
                  variant='standard'
                  sx={{ width: '100%', mt: 1 }}
                />
              )}

              {fields.mac && (
                <ClearingTextField
                  name={`${data}.mac`}
                  options={{ value: warrantyResponse?.mac }}
                  id='tf-warranty-mac'
                  label={'МАС ПУ'}
                  variant='standard'
                  sx={{ width: '100%', mt: 1 }}
                />
              )}
              {fields.stampNumber && (
                <ClearingTextField
                  name={`${data}.stampNumber`}
                  options={{ value: warrantyResponse?.stampNumber }}
                  id='tf-warranty-plomb'
                  label={'Номер пломбы'}
                  variant='standard'
                  sx={{ width: '100%', mt: 1 }}
                />
              )}
            </Grid>
            <Grid
              item
              xs={2}
            >
              <ClearingTextField
                name={`${data}.executor`}
                options={{ value: warrantyResponse?.executor }}
                id='tf-warranty-userName'
                label={'Работу выполнил'}
                variant='standard'
                sx={{ width: '100%', mt: 1 }}
              />
              {fields.tariff && (
                <AutocompleteValue
                  sx={{ mt: 1 }}
                  title='Тариф'
                  defaultValue={warrantyResponse?.measurement?.tariff?.toString()}
                  fieldName={`${data}.measurement.tariff`}
                  values={Array.of('1', '2', '3')}
                />
              )}
              {fields.ktt && (
                <ClearingTextField
                  name={`${data}.taskTransformator.ktt`}
                  options={{ value: warrantyResponse?.taskTransformator?.ktt }}
                  id='tf-warranty-ktt'
                  label={'КТТ нового ТТ'}
                  variant='standard'
                  sx={{ width: '100%', mt: 1 }}
                />
              )}
              {fields.automaticBreakerId && (
                <AutocompleteValue
                  sx={{ mt: 1 }}
                  title='Тип АВ'
                  defaultValue={
                    !optionsAutomaticBreaker || optionsAutomaticBreaker?.size === 0
                      ? warrantyResponse?.automaticBreakerId &&
                        getCatalogMapWithErr('automaticBreaker').get(
                          warrantyResponse.automaticBreakerId
                        )
                      : warrantyResponse?.automaticBreakerId
                  }
                  fieldName={`${data}.automaticBreakerId`}
                  values={optionsAutomaticBreaker || []}
                />
              )}
              {fields.sim && (
                <TextField
                  id='tf-warranty-sim'
                  label={'ICCiD Sim'}
                  variant='standard'
                  sx={{ width: '100%', mt: 1 }}
                  disabled
                  defaultValue={warrantyResponse?.ICCD || ''}
                />
              )}
            </Grid>
            {fields.taskTransformator &&
              (warrantyResponse?.taskTransformator?.unmount?.phaseList?.length ||
                warrantyResponse?.taskTransformator?.mount?.phaseList?.length) && (
                <Stack>
                  {warrantyResponse?.taskTransformator?.mount?.phaseList.map(
                    (mountPhase, index) => {
                      const unmountPhase =
                        warrantyResponse?.taskTransformator?.unmount?.phaseList[index] ||
                        ({} as Phase);
                      return (
                        <Grid
                          key={index}
                          item
                          xs={12}
                          sx={{ pl: 1, pr: 2, mt: 3 }}
                          container
                        >
                          <Grid
                            item
                            xs={1}
                            justifyContent={'center'}
                            display={'flex'}
                            alignItems={'center'}
                          >
                            {mountPhase.phase}
                          </Grid>
                          <Grid
                            item
                            xs={11}
                            display={'flex'}
                            gap={2}
                          >
                            <ClearingTextField
                              name={`${data}.taskTransformator.mount.phaseList[${index}].id`}
                              options={{ value: mountPhase?.id }}
                              id={`mount.taskTransformator.phaseList.${index}.id`}
                              type='hidden'
                              variant='standard'
                              sx={{ display: 'none' }}
                            />
                            <ClearingTextField
                              name={`${data}.taskTransformator.unmount.phaseList[${index}].id`}
                              options={{ value: unmountPhase?.id }}
                              id={`unmount.taskTransformator.phaseList.${index}.id`}
                              type='hidden'
                              variant='standard'
                              sx={{ display: 'none' }}
                            />
                            {/* Поле для "Номер нового ТТ" из mount */}
                            <ClearingTextField
                              name={`${data}.taskTransformator.mount.phaseList[${index}].serial`}
                              options={{ value: mountPhase?.serial }}
                              id={`mount.phaseList.${index}.serial`}
                              label={'Номер нового ТТ'}
                              variant='standard'
                              sx={{ width: '30%', mt: 1 }}
                            />
                            {/* Поле для "Пломба ТТ" из mount */}
                            <ClearingTextField
                              name={`${data}.taskTransformator.mount.phaseList[${index}].stamp`}
                              options={{ value: mountPhase?.stamp }}
                              id={`mount.taskTransformator.phaseList.${index}.stamp`}
                              label={'Пломба ТТ'}
                              variant='standard'
                              sx={{ width: '30%', mt: 1 }}
                            />
                            {/* Скрытое поле для "Пломба снятого ТТ" из unmount */}
                            <ClearingTextField
                              name={`${data}.taskTransformator.unmount.phaseList[${index}].stamp`}
                              options={{ value: unmountPhase?.stamp }}
                              id={`unmount.taskTransformator.phaseList.${index}.stamp`}
                              type='hidden'
                              variant='standard'
                              sx={{ display: 'none' }}
                            />
                            {/* Скрытое поле для "год" из unmount */}
                            <ClearingTextField
                              name={`${data}.taskTransformator.unmount.phaseList[${index}].newYear`}
                              options={{ value: unmountPhase?.newYear }}
                              id={`unmount.taskTransformator.phaseList.${index}.newYear`}
                              type='hidden'
                              variant='standard'
                              sx={{ display: 'none' }}
                            />
                            {/* Скрытое поле для "год" из mount */}
                            <ClearingTextField
                              name={`${data}.taskTransformator.mount.phaseList[${index}].newYear`}
                              options={{ value: mountPhase?.newYear }}
                              id={`mount.phaseList.${index}.newYear`}
                              type='hidden'
                              variant='standard'
                              sx={{ display: 'none' }}
                            />
                            {/* Скрытое поле для "фаза" из mount */}
                            <ClearingTextField
                              name={`${data}.taskTransformator.mount.phaseList[${index}].phase`}
                              options={{ value: mountPhase?.phase }}
                              id={`mount.taskTransformator.phaseList.${index}.phase`}
                              type='hidden'
                              variant='standard'
                              sx={{ display: 'none' }}
                            />
                            {/* Скрытое поле для "фаза" из unmount */}
                            <ClearingTextField
                              name={`${data}.taskTransformator.unmount.phaseList[${index}].phase`}
                              options={{ value: unmountPhase?.phase }}
                              id={`unmount.taskTransformator.phaseList.${index}.phase`}
                              type='hidden'
                              variant='standard'
                              sx={{ display: 'none' }}
                            />
                            {/* Поле для "Номер снятого ТТ" из unmount */}
                            <ClearingTextField
                              name={`${data}.taskTransformator.unmount.phaseList[${index}].serial`}
                              options={{ value: unmountPhase?.serial }}
                              id={`unmount.taskTransformator.phaseList.${index}.serial`}
                              label={'Номер снятого ТТ'}
                              variant='standard'
                              sx={{ width: '30%', mt: 1 }}
                            />
                          </Grid>
                        </Grid>
                      );
                    }
                  )}
                </Stack>
              )}

            {fields.measurement ? (
              <>
                <Grid
                  item
                  xs={3}
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Typography>
                    <span style={{ fontWeight: 'bold' }}>Старый тариф</span>
                  </Typography>
                  <ClearingTextField
                    name={`${data}.oldMeasurement.t1`}
                    options={{ value: warrantyResponse?.oldMeasurement?.t1 }}
                    id='tf-warranty-t1'
                    label={'Т1'}
                    variant='standard'
                    sx={{ width: '100%', mt: 1 }}
                  />
                  <ClearingTextField
                    name={`${data}.oldMeasurement.t2`}
                    options={{ value: warrantyResponse?.oldMeasurement?.t2 }}
                    id='tf-warranty-t2'
                    label={'Т2'}
                    variant='standard'
                    sx={{ width: '100%', mt: 1 }}
                  />
                  <ClearingTextField
                    name={`${data}.oldMeasurement.t3`}
                    options={{ value: warrantyResponse?.oldMeasurement?.t3 }}
                    id='tf-warranty-t3'
                    label={'Т3'}
                    variant='standard'
                    sx={{ width: '100%', mt: 1 }}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Typography>
                    <span style={{ fontWeight: 'bold' }}>Новый тариф</span>
                  </Typography>
                  <ClearingTextField
                    name={`${data}.measurement.t1`}
                    options={{ value: warrantyResponse?.measurement?.t1 }}
                    id='tf-warranty-t1-new'
                    label={'Т1'}
                    variant='standard'
                    sx={{ width: '100%', mt: 1 }}
                  />
                  <ClearingTextField
                    name={`${data}.measurement.t2`}
                    options={{ value: warrantyResponse?.measurement?.t2 }}
                    id='tf-warranty-t2-new'
                    label={'Т2'}
                    variant='standard'
                    sx={{ width: '100%', mt: 1 }}
                  />
                  <ClearingTextField
                    name={`${data}.measurement.t3`}
                    options={{ value: warrantyResponse?.measurement?.t3 }}
                    id='tf-warranty-t3-new'
                    label={'Т3'}
                    variant='standard'
                    sx={{ width: '100%', mt: 1 }}
                  />
                </Grid>
              </>
            ) : (
              <Grid
                item
                xs={6}
              />
            )}
            <Grid
              item
              container
              spacing={2}
              xs={6}
            >
              {fields.previousAccountingPuNumber && (
                <Grid
                  item
                  xs={6}
                >
                  <ClearingTextField
                    name={`${data}.previousAccountingPuNumber`}
                    options={{
                      value: warrantyResponse?.transferToAccounting?.previousAccountingPuNumber,
                    }}
                    id='tf-warranty-previousAccountingPuNumber'
                    label={'Номер ПУ предыдущего в расчете'}
                    variant='standard'
                    sx={{ width: '100%', mt: 1 }}
                  />
                </Grid>
              )}

              {fields.previousAccountingPuType ? (
                <Grid
                  item
                  xs={6}
                >
                  <ClearingTextField
                    name={`${data}.previousAccountingPuType`}
                    options={{
                      value: warrantyResponse?.transferToAccounting?.previousAccountingPuType,
                    }}
                    id='tf-warranty-previousAccountingPuType'
                    label={'Тип ПУ предыдущего в расчете'}
                    variant='standard'
                    sx={{ width: '100%', mt: 1 }}
                  />
                </Grid>
              ) : (
                <Grid
                  item
                  xs={6}
                />
              )}

              {fields.newAccountingPuNumber && (
                <Grid
                  item
                  xs={6}
                >
                  <ClearingTextField
                    name={`${data}.newAccountingPuNumber`}
                    options={{
                      value: warrantyResponse?.transferToAccounting?.newAccountingPuNumber,
                    }}
                    id='tf-warranty-newAccountingPuNumber'
                    label={'Номер нового ПУ ставшего расчетным'}
                    variant='standard'
                    sx={{ width: '100%', mt: 1 }}
                  />
                </Grid>
              )}

              {fields.newAccountingPuType ? (
                <Grid
                  item
                  xs={6}
                >
                  <ClearingTextField
                    name={`${data}.newAccountingPuType`}
                    options={{ value: warrantyResponse?.transferToAccounting?.newAccountingPuType }}
                    id='tf-warranty-newAccountingPuType'
                    label={'Тип нового ПУ ставшего расчетным'}
                    variant='standard'
                    sx={{ width: '100%', mt: 1 }}
                  />
                </Grid>
              ) : (
                <Grid
                  item
                  xs={6}
                />
              )}
            </Grid>

            {fields.longitude && fields.latitude && (
              <>
                <Grid
                  item
                  xs={3}
                >
                  <ClearingTextField
                    name={`${data}.latitude`}
                    options={{ value: warrantyResponse?.latitude }}
                    id='tf-warranty-latitude'
                    label={'Широта'}
                    variant='standard'
                    sx={{ width: '100%', mt: 1 }}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                >
                  <ClearingTextField
                    name={`${data}.longitude`}
                    options={{ value: warrantyResponse?.longitude }}
                    id='tf-warranty-longitude'
                    label={'Долгота'}
                    variant='standard'
                    sx={{ width: '100%', mt: 1 }}
                  />
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
            >
              <ClearingTextField
                name={`${data}.comment`}
                options={{ value: warrantyResponse?.comment }}
                id='tf-warranty-py'
                label={'Комментарий'}
                variant='standard'
                sx={{ width: '100%', mt: 1 }}
              />
            </Grid>
          </Grid>

          <GSMBlockNew data={warrantyResponse.simChangeList ?? []} />

          <Grid
            container
            item
            direction='row'
            justifyContent='space-between'
          >
            <Grid>
              <Button
                variant='text'
                onClick={handleCancelWarrantyWork}
                disabled={disableCancelWarrantyButton}
              >
                Отменить гарантийную работу
              </Button>
            </Grid>

            <Grid item>
              <Grid
                container
                direction='row'
              >
                <Grid item>
                  <SaveIcon
                    onClick={methods?.handleSubmit(onSave)}
                    sx={{
                      color: 'gray',
                      fontSize: 22,
                      cursor: disabledAll || onlyShow ? '' : 'pointer',
                      ml: 2,
                    }}
                  />
                </Grid>
                <Grid item>
                  <ExpandButton
                    expand={mode === ModeViewerResult.LARGE}
                    onAction={handleMode(ModeViewerResult.LARGE)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      )}
    </BoxShadow>
  );
}

export default WarrantyPanel;
