import { FilterArgEnum } from '@/components/inputs/ArgumentSelectNew/types';
import { ComplaintTypeReturn, EComplaintSource, EComplaintType, IComplaintItem } from './types';
import imgMas from '@/images/icons/issues/mas.svg';
import imgCallCenter from '@/images/icons/issues/call-center.svg';
import admin from '@/images/icons/issues/admin.svg';

import { useEffect, useMemo, useState } from 'react';
import { getWaranty } from '@/services/WarantyService';
import { fetchCatch } from '@/hooks/ActionLogHook';
import { useComplaintStore } from './store';
import { findAllResultByTaskIdAndType } from '@/services/TaskService';
import { TaskResultType } from '@/dto/taskmap/task/TaskResultDto';
import { collectUniqueImages } from '../taskEditor/panel/utils';

type FILTERS_LABELS_TYPE = {
  [key: string]: string;
};

export const SAVE_FILTER_KEY_ISSUES = 'Complaints';

export const FILTERS_LABELS: FILTERS_LABELS_TYPE = {
  address: 'Адрес',
  PU: '№ ПУ',
  fio: 'ФИО',
  account: 'Лицевой счет',
  stage: 'Этап',
  text: 'Текст комментария',
  meterNumber: 'Номер пу',
  letterDetails: 'Детали письма',
  declarerFio: 'Фио заявителя',
  phoneNumber: 'Номер телефона',
  sapNumber: 'Номер САП',
  type: 'Тип',
  res: 'РЭС',
  id: 'ID жалобы',
  inputId: 'ID ввода',
  pes: 'ПЭС',
  responsibleId: 'Ответственный',
  executorId: 'Исполнитель',
  source: 'Источник жалобы',
  contractId: 'Контракт',
  createAt: 'Дата создания',
  classification: 'Классификация',
};

const data: { id: string; dType: string }[] = [
  {
    id: 'stage',
    dType: 'TAG',
  },
  {
    id: 'address',
    dType: 'TEXT',
  },
  {
    id: 'text',
    dType: 'TEXT',
  },
  {
    id: 'meterNumber',
    dType: 'TEXT',
  },
  {
    id: 'letterDetails',
    dType: 'TEXT',
  },
  {
    id: 'account',
    dType: 'TEXT',
  },
  {
    id: 'declarerFio',
    dType: 'TEXT',
  },
  {
    id: 'type',
    dType: 'TAG',
  },
  {
    id: 'res',
    dType: 'TAG',
  },
  {
    id: 'pes',
    dType: 'TAG',
  },
  {
    id: 'responsibleId',
    dType: 'TAG',
  },
  {
    id: 'executorId',
    dType: 'TAG',
  },
  {
    id: 'contractId',
    dType: 'TAG',
  },
  {
    id: 'source',
    dType: 'TAG',
  },
  {
    id: 'id',
    dType: 'EQUEL_TEXT',
  },
  {
    id: 'sapNumber',
    dType: 'TEXT',
  },
  {
    id: 'phoneNumber',
    dType: 'TEXT',
  },
  {
    id: 'inputId',
    dType: 'EQUEL_TEXT',
  },
  {
    id: 'createAt',
    dType: 'DATE',
  },
  {
    id: 'classification',
    dType: 'TAG',
  },
];

export const complaintFilerData = data
  .map((elem) => ({
    ...elem,
    value: elem.id,
    label: FILTERS_LABELS[elem.id],
    isActive: false,
  }))
  .sort((a, b) => a.label.localeCompare(b.label));

export const typeCatalogOptions = [
  { value: 'COMPLAINT', label: 901 },
  { value: 'REPEATED_COMPLAINT', label: 902 },
  { value: 'APPEAL', label: 903 },
];

export const tariffOptions = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
];

export const defaultArgInput = {
  TAG: {
    argList: [FilterArgEnum.IN_LIST, FilterArgEnum.NOT_IN_LIST],
    defaultArgNum: 0,
  },
  TEXT: {
    argList: [
      FilterArgEnum.EQUAL,
      FilterArgEnum.NOT_EQUAL,
      FilterArgEnum.IN_LIST,
      FilterArgEnum.NOT_IN_LIST,
      FilterArgEnum.CONTAIN,
    ],
    defaultArgNum: 4,
  },
  EQUEL_TEXT: {
    argList: [FilterArgEnum.EQUAL, FilterArgEnum.NOT_EQUAL],
    defaultArgNum: 2,
  },
  DATE: {
    argList: [
      FilterArgEnum.LESS,
      FilterArgEnum.MORE,
      FilterArgEnum.EQUAL,
      FilterArgEnum.NOT_EQUAL,
      FilterArgEnum.RANGE,
    ],
    defaultArgNum: 2,
  },
  DOUBLE: {
    argList: [
      FilterArgEnum.LESS,
      FilterArgEnum.MORE,
      FilterArgEnum.IN_LIST,
      FilterArgEnum.NOT_IN_LIST,
    ],
    defaultArgNum: 0,
  },
  LONG: {
    argList: [
      FilterArgEnum.LESS,
      FilterArgEnum.MORE,
      FilterArgEnum.IN_LIST,
      FilterArgEnum.NOT_IN_LIST,
    ],
    defaultArgNum: 0,
  },
};

// FIXME:типизация
export const getComplaintsWithFilesFix = (data: any) => {
  return data?.content?.map((complaint: any) => ({
    ...complaint,
    files: complaint.files
      ? complaint.files.map((file: any) => ({
          ...file,
          id: file.base.id,
        }))
      : null,
  }));
};
export const getComplaintsWithFilesFixSingle = (complaintDTO: IComplaintItem) => {
  return {
    ...complaintDTO,
    files: complaintDTO.files
      ? complaintDTO.files.map((file: any) => ({
          ...file,
          id: file.base.id,
        }))
      : null,
  };
};

export const useComplaintIcon = (source?: EComplaintSource) => {
  return useMemo(() => {
    switch (source) {
      case EComplaintSource.ROS_SETI_MR:
        return imgMas;

      case EComplaintSource.CALL_CENTER:
        return imgCallCenter;

      default:
        return admin;
    }
  }, [source]);
};

export const useGalleryDataFromTask = () => {
  const { selectedCompliant } = useComplaintStore((state) => ({
    selectedCompliant: state.selectedCompliant,
  }));
  const findRes = () => {
    const taskId = selectedCompliant?.complaint.resultTaskId;
    if (taskId && selectedCompliant.complaint.type !== 'ODPU') {
      findAllResultByTaskIdAndType(taskId, TaskResultType.REPLACEMENT)
        .then(({ data }) => {
          setPhotosList((prev: any) => {
            return [...prev, ...collectUniqueImages(data).filter((item) => item)];
          });
          findWarranty(taskId);
        })
        .catch((err) => fetchCatch(err, 'Ошибка при загрузке информации о замене'));
    } else {
      if (taskId) {
        findAllResultByTaskIdAndType(taskId, TaskResultType.ODPU)
          .then(({ data }) => {
            setPhotosList((prev: any) => {
              return [...prev, ...collectUniqueImages({ data }).filter((item) => item)];
            });
          })
          .catch((err) => fetchCatch(err, 'Ошибка при загрузке информации о замене'));
      }
    }
  };
  const findWarranty = (taskId: string) => {
    getWaranty(taskId)
      .then(({ data }) => {
        setPhotosList((prev: any) => {
          return [...prev, ...collectUniqueImages(data).filter((item) => item)];
        });
      })
      .catch((err) => fetchCatch(err, 'Ошибка при загрузке информации о гарантии'));
  };

  const [photosList, setPhotosList] = useState<any>([]);
  useEffect(() => {
    if (selectedCompliant?.complaint.base.id) {
      findRes();
      setPhotosList([]);
    }
  }, [selectedCompliant?.complaint.base.id]);

  return photosList;
};

export const getUrl = (imgIds: string[]) => {
  let result = '/gallery?title=Фото жалобы';
  imgIds.forEach((elem) => (result += `&ids=${elem}`));
  return result;
};

export const DEFAULT_ACCEPTED_EXTENSIONS_COMPLAINTS =
  '.pdf, .doc, .docs, .jpeg, .jpg, .png, .xls, .xlsx, .jfif, .jpe, .xlsm, .xlsb, .xlt';
export const NO_CALL_COUNT = 3;

const urlAPI = {
  [EComplaintType.ODPU]: 'odpu',
  [EComplaintType.ENERGY_SERVICE]: 'energy',
  [EComplaintType.UNKNOWN]: '',
};

export const getComplaintType = (complaintType: string | undefined | null): ComplaintTypeReturn => {
  switch (complaintType) {
    case EComplaintType.ODPU:
      return { url: urlAPI[EComplaintType.ODPU], type: EComplaintType.ODPU };
    case EComplaintType.ENERGY_SERVICE:
      return { url: urlAPI[EComplaintType.ENERGY_SERVICE], type: EComplaintType.ENERGY_SERVICE };
    default:
      console.error(`Unknown complaint type: ${complaintType}`);
      return { url: urlAPI[EComplaintType.UNKNOWN], type: EComplaintType.UNKNOWN };
  }
};
export const isTypeEnergyService = (type: EComplaintType | undefined) =>
  type === EComplaintType.ENERGY_SERVICE;
export const isTypeODPU = (type: EComplaintType | undefined) => type === EComplaintType.ODPU;

export const consumerTypeOptions = [
  { value: 'FIZ', label: 'Физическое лицо' },
  { value: 'YUR', label: 'Юридическое лицо' },
];
